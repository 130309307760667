.erpnext-footer {
  margin: 11px auto;
  text-align: center;
}

.show-all-reports {
  margin-top: 5px;
  font-size: 11px;
}

/* toolbar */
.toolbar-splash {
  width: 32px;
  height: 32px;
  margin: -10px auto;
}

.erpnext-icon {
  width: 24px;
  margin-right: 0px;
  margin-top: -3px;
}

.app-icon-svg {
  display: inline-block;
  margin: auto;
  text-align: center;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.app-icon-svg .hub-icon {
  height: 72px;
  width: 72px;
}

.dashboard-list-item {
  background-color: inherit;
  border-bottom: 1px solid var(--border-color);
  font-size: var(--text-md);
  color: var(--text-color);
}

#page-stock-balance .dashboard-list-item {
  padding: 5px 15px;
}

.dashboard-list-item:last-child {
  border-bottom: none;
}

.frappe-control[data-fieldname=result_html] {
  overflow: scroll;
}

.assessment-result-tool {
  table-layout: fixed;
}
.assessment-result-tool input {
  width: 100%;
  border: 0;
  outline: none;
  text-align: right;
}
.assessment-result-tool th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.assessment-result-tool .total-score,
.assessment-result-tool .grade,
.assessment-result-tool .score {
  text-align: right;
}

/* pos */
body[data-route=pos] .pos-bill-toolbar {
  padding: 10px 0px;
  height: 51px;
}
body[data-route=pos] .pos-bill-item:hover,
body[data-route=pos] .list-customers-table > .pos-list-row:hover {
  background-color: #f5f7fa;
  cursor: pointer;
}
body[data-route=pos] .pos-item-qty {
  display: inline-block;
}
body[data-route=pos] .pos-qty-row > div {
  padding: 5px 0px;
}
body[data-route=pos] .pos-qty-btn {
  margin-top: 3px;
  cursor: pointer;
  font-size: 120%;
}
body[data-route=pos] .search-area .form-group {
  max-width: 100% !important;
}
body[data-route=pos] .tax-table {
  margin-bottom: 10px;
}
body[data-route=pos] .discount-field-col {
  padding-left: 24px;
}
body[data-route=pos] .discount-amount-area .input-group:first-child {
  margin-bottom: 2px;
}
body[data-route=pos] .payment-toolbar .row {
  width: 323px;
  margin: 0 auto;
}
body[data-route=pos] .payment-mode {
  cursor: pointer;
  font-family: sans-serif;
  font-size: 15px;
}
body[data-route=pos] .pos-payment-row .col-xs-6 {
  padding: 15px;
}
body[data-route=pos] .pos-payment-row {
  border-bottom: 1px solid var(--border-color);
  margin: 2px 0px 5px 0px;
  height: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
}
body[data-route=pos] .pos-payment-row:hover,
body[data-route=pos] .pos-keyboard-key:hover {
  background-color: var(--bg-color);
  cursor: pointer;
}
body[data-route=pos] .pos-keyboard-key,
body[data-route=pos] .delete-btn {
  border: 1px solid var(--border-color);
  height: 85px;
  width: 85px;
  margin: 10px 10px;
  font-size: 24px;
  font-weight: 200;
  background-color: #fdfdfd;
  border-color: #e8e8e8;
}
body[data-route=pos] .numeric-keypad {
  border: 1px solid var(--border-color);
  height: 69px;
  width: 69px;
  font-size: 20px;
  font-weight: 200;
  background-color: #fdfdfd;
  border-color: #e8e8e8;
  margin-left: -4px;
}
body[data-route=pos] .pos-pay {
  height: 69px;
  width: 69px;
  font-size: 17px;
  font-weight: 200;
  margin-left: -4px;
}
body[data-route=pos] .numeric-keypad {
  height: 60px;
  width: 60px;
  font-size: 20px;
  font-weight: 200;
  border-radius: 0;
  background-color: #fff;
  margin-left: -4px;
}
@media (max-width: var(--xl-width)) {
  body[data-route=pos] .numeric-keypad {
    height: 45px;
    width: 45px;
    font-size: 14px;
  }
}
@media (max-width: var(--lg-width)) {
  body[data-route=pos] .numeric-keypad {
    height: 40px;
    width: 40px;
  }
}
body[data-route=pos] .numeric_keypad {
  margin-left: -15px;
}
body[data-route=pos] .numeric_keypad > .row > button {
  border: none;
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
body[data-route=pos] .numeric_keypad > .row > button:first-child {
  border-left: 1px solid var(--border-color);
}
body[data-route=pos] .numeric_keypad > .row:first-child > button {
  border-top: 1px solid var(--border-color);
}
body[data-route=pos] .pos-pay {
  background-color: var(--primary);
  border: none;
}
body[data-route=pos] .multimode-payments {
  padding-left: 30px;
}
body[data-route=pos] .payment-toolbar {
  padding-right: 30px;
}
body[data-route=pos] .list-row-head.pos-invoice-list {
  border-top: 1px solid var(--border-color);
}
body[data-route=pos] .modal-dialog {
  width: 750px;
}
@media (max-width: var(--md-width)) {
  body[data-route=pos] .modal-dialog {
    width: auto;
  }
  body[data-route=pos] .modal-dialog .modal-content {
    height: auto;
  }
}
@media (max-width: var(--md-width)) {
  body[data-route=pos] .amount-row h3 {
    font-size: 15px;
  }
  body[data-route=pos] .pos-keyboard-key,
  body[data-route=pos] .delete-btn {
    height: 50px;
  }
  body[data-route=pos] .multimode-payments {
    padding-left: 15px;
  }
  body[data-route=pos] .payment-toolbar {
    padding-right: 15px;
  }
}
body[data-route=pos] .amount-label {
  font-size: 16px;
}
body[data-route=pos] .selected-payment-mode {
  background-color: var(--bg-color);
  cursor: pointer;
}
body[data-route=pos] .pos-invoice-list {
  padding: 15px 10px;
}
body[data-route=pos] .write_off_amount,
body[data-route=pos] .change_amount {
  margin: 15px;
  width: 130px;
}
body[data-route=pos] .pos-list-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 9px 15px;
  font-size: 12px;
  margin: 0px;
  border-bottom: 1px solid var(--border-color);
}
body[data-route=pos] .pos-list-row .cell {
  display: table-cell;
  vertical-align: middle;
}
body[data-route=pos] .pos-list-row .cell.price-cell {
  width: 50%;
}
body[data-route=pos] .pos-list-row .subject {
  width: 40%;
}
body[data-route=pos] .pos-list-row .list-row-checkbox,
body[data-route=pos] .pos-list-row .list-select-all {
  margin-right: 7px;
}
body[data-route=pos] .pos-bill-header {
  background-color: #f5f7fa;
  border: 1px solid var(--border-color);
  padding: 13px 15px;
}
body[data-route=pos] .pos-list-row.active {
  background-color: var(--fg-hover-color);
}
body[data-route=pos] .totals-area {
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  margin-bottom: 15px;
}
body[data-route=pos] .tax-area .pos-list-row {
  border: none;
}
body[data-route=pos] .item-cart-items {
  height: calc(100vh - 526px);
  overflow: auto;
  border: 1px solid var(--border-color);
  border-top: none;
}
@media (max-width: var(--md-width)) {
  body[data-route=pos] .item-cart-items {
    height: 30vh;
  }
}
body[data-route=pos] .no-items-message {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
body[data-route=pos] .pos-list-row:last-child {
  border-bottom: none;
}
body[data-route=pos] .form-section-heading {
  padding: 0;
}
body[data-route=pos] .item-list {
  border: 1px solid var(--border-color);
  border-top: none;
  max-height: calc(100vh - 190px);
  overflow: auto;
}
@media (max-width: var(--md-width)) {
  body[data-route=pos] .item-list {
    max-height: initial;
  }
}
body[data-route=pos] .item-list .image-field {
  height: 140px;
}
body[data-route=pos] .item-list .image-field .placeholder-text {
  font-size: 50px;
}
body[data-route=pos] .item-list .pos-item-wrapper {
  position: relative;
}
body[data-route=pos] .pos-bill-toolbar {
  margin-top: 10px;
}
body[data-route=pos] .search-item .form-group {
  margin: 0;
}
body[data-route=pos] .item-list-area .pos-bill-header {
  padding: 5px;
  padding-left: 15px;
}
body[data-route=pos] .pos-selected-item-action .pos-list-row:first-child {
  padding-top: 0;
}
body[data-route=pos] .pos-selected-item-action > .pos-list-row {
  border: none;
}
@media (max-width: var(--xl-width)) {
  body[data-route=pos] .pos-selected-item-action > .pos-list-row {
    padding: 5px 15px;
  }
}
body[data-route=pos] .edit-customer-btn {
  position: absolute;
  right: 57px;
  top: 15px;
  z-index: 100;
}
body[data-route=pos] .btn-more {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--bg-color);
  min-height: 200px;
}
body[data-route=pos] .collapse-btn {
  cursor: pointer;
}
@media (max-width: var(--md-width)) {
  body[data-route=pos] .page-actions {
    max-width: 110px;
  }
}

.price-info {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0 0 15px 15px;
  background-color: rgba(141, 153, 166, 0.6);
  padding: 5px 9px;
  border-radius: 3px;
  color: #fff;
}

.exercise-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  padding: 6px 6px 6px 8px;
  margin-top: 10px;
  height: 100% !important;
}
.exercise-card .card-img-top {
  width: 100%;
  height: 15vw;
  -o-object-fit: cover;
     object-fit: cover;
}
.exercise-card .btn-edit {
  position: absolute;
  bottom: 10px;
  left: 20px;
}
.exercise-card .btn-del {
  position: absolute;
  bottom: 10px;
  left: 50px;
}
.exercise-card .card-body {
  margin-bottom: 10px;
}
.exercise-card .card-footer {
  padding: 10px;
}

.exercise-row {
  height: 100% !important;
  display: flex;
  flex-wrap: wrap;
}

.exercise-col {
  padding: 10px;
}

.plant-floor,
.workstation-wrapper,
.workstation-card p {
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  box-shadow: none;
  background-color: var(--card-bg);
  position: relative;
}

.ring-container {
  position: relative;
}

.circle {
  width: 9px;
  height: 9px;
  background-color: #278f5e;
  border-radius: 50%;
  position: absolute;
  left: 9px;
  top: 8px;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.ringring {
  border: 2px solid #62bd19;
  -webkit-border-radius: 40px;
  height: 15px;
  width: 15px;
  position: absolute;
  left: 6px;
  top: 5px;
  -webkit-animation: pulsate 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

.plant-floor {
  padding-bottom: 25px;
}

.workstation-image {
  cursor: pointer;
}

.workstation-image-container {
  height: 4.5rem;
}

.workstation-image-cls {
  padding-top: 2px;
}

.plant-floor-filter {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.plant-floor-container {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 6px;
}

.workstation-off {
  opacity: 0.6;
}

@media screen and (max-width: 620px) {
  .plant-floor-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.plant-floor-container .workstation-card {
  padding: 5px;
}

.plant-floor-container .workstation-image-link {
  width: 100%;
  font-size: 50px;
  margin: var(--margin-xs);
  min-height: 9rem;
}

.workstation-abbr {
  display: flex;
  background-color: var(--control-bg);
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.frappe-control[data-fieldname=other_charges_calculation] .ql-editor,
.frappe-control[data-fieldname=address_display] .ql-editor,
.frappe-control[data-fieldname=shipping_address_display] .ql-editor,
.frappe-control[data-fieldname=shipping_address] .ql-editor,
.frappe-control[data-fieldname=dispatch_address] .ql-editor,
.frappe-control[data-fieldname=source_address_display] .ql-editor,
.frappe-control[data-fieldname=target_address_display] .ql-editor,
.frappe-control[data-fieldname=billing_address_display] .ql-editor,
.frappe-control[data-fieldname=company_address_display] .ql-editor {
  white-space: normal;
}

.call-popup a:hover {
  text-decoration: underline;
}
.call-popup .for-description {
  max-height: 250px;
  overflow: scroll;
}

audio {
  height: 40px;
  width: 100%;
  max-width: 500px;
  background-color: var(--control-bg);
  border-radius: var(--border-radius-sm);
  outline: none;
}
audio-webkit-media-controls-panel {
  background: var(--control-bg);
}

.point-of-sale-app {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  gap: var(--margin-md);
  padding: 1%;
}
.point-of-sale-app section {
  min-height: 45rem;
  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
}
.point-of-sale-app .frappe-control {
  margin: 0 !important;
  width: 100%;
}
.point-of-sale-app .form-group {
  margin-bottom: 0px !important;
}
.point-of-sale-app .pointer-no-select, .point-of-sale-app > .payment-container > .fields-numpad-container > .number-pad .numpad-container > .numpad-btn, .point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .cash-shortcuts > .shortcut, .point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment, .point-of-sale-app > .payment-container .section-label, .point-of-sale-app > .item-details-container > .form-container > .auto-fetch-btn, .point-of-sale-app > .item-details-container > .item-details-header > .close-btn, .point-of-sale-app .invoice-wrapper, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-container > .numpad-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper, .point-of-sale-app > .items-selector > .items-container > .item-wrapper, .point-of-sale-app .primary-action, .point-of-sale-app > .payment-container .submit-order-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .checkout-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .edit-cart-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .checkout-btn {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.point-of-sale-app .nowrap, .point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-name, .point-of-sale-app .invoice-wrapper > .invoice-name-date > .invoice-date, .point-of-sale-app .invoice-wrapper > .invoice-name-date > .invoice-name, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-name-desc, .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-name-desc, .point-of-sale-app > .items-selector > .items-container > .item-wrapper > .item-detail > .item-name {
  overflow: hidden;
  white-space: nowrap;
}
.point-of-sale-app .image, .point-of-sale-app > .item-details-container > .item-display > .item-image > img, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-image > img, .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-image > img, .point-of-sale-app > .items-selector > .items-container > .item-wrapper .item-img, .point-of-sale-app > .items-selector > .items-container > .item-wrapper .item-display > img {
  height: 100% !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.point-of-sale-app .abbr, .point-of-sale-app > .item-details-container > .item-display > .item-image > .item-abbr, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-abbr, .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-abbr {
  background-color: var(--control-bg);
  font-size: var(--text-3xl);
}
.point-of-sale-app .label, .point-of-sale-app > .payment-container .section-label, .point-of-sale-app > .payment-container .point-of-sale-app > .items-selector > .filter-section > .section-label, .point-of-sale-app > .payment-container .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .header > .section-label, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-label, .point-of-sale-app > .point-of-sale-app.customer-cart-container > .customer-cart-container.customer-section > .cart-container.customer-details > .abs-cart-container.header > .cart-label, .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .transactions-label, .point-of-sale-app > .customer-cart-container.point-of-sale-app > .customer-section.items-selector > .customer-details.filter-section > .transactions-label, .point-of-sale-app > .point-of-sale-app.customer-cart-container > .customer-cart-container.customer-section > .customer-section.customer-details > .customer-details.header > .transactions-label, .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .header > .label, .point-of-sale-app > .items-selector > .filter-section > .label {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: var(--text-lg);
}
.point-of-sale-app .pos-card, .point-of-sale-app > .past-order-summary > .invoice-summary-wrapper, .point-of-sale-app > .past-order-list, .point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment, .point-of-sale-app > .payment-container, .point-of-sale-app > .item-details-container, .point-of-sale-app > .customer-cart-container > .cart-container, .point-of-sale-app > .customer-cart-container > .customer-section, .point-of-sale-app > .items-selector {
  background-color: var(--fg-color);
  box-shadow: var(--shadow-base);
  border-radius: var(--border-radius-md);
}
.point-of-sale-app .seperator {
  margin-left: var(--margin-sm);
  margin-right: var(--margin-md);
  border-bottom: 1px solid var(--gray-300);
}
.point-of-sale-app .primary-action, .point-of-sale-app > .payment-container .submit-order-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .checkout-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .edit-cart-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .checkout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm);
  margin-top: var(--margin-sm);
  border-radius: var(--border-radius-md);
  font-size: var(--text-lg);
  font-weight: 700;
}
.point-of-sale-app .highlighted-numpad-btn {
  box-shadow: inset 0 0px 4px 0px rgba(0, 0, 0, 0.15) !important;
  font-weight: 700;
  background-color: var(--control-bg);
}
.point-of-sale-app > .items-selector {
  grid-column: span 6/span 6;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.point-of-sale-app > .items-selector > .filter-section {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  background-color: var(--fg-color);
  padding: var(--padding-lg);
  padding-bottom: var(--padding-sm);
  align-items: center;
}
.point-of-sale-app > .items-selector > .filter-section > .label, .point-of-sale-app > .payment-container .point-of-sale-app > .items-selector > .filter-section > .section-label, .point-of-sale-app > .payment-container .point-of-sale-app > .customer-cart-container > .customer-section.point-of-sale-app > .customer-details.items-selector > .header.filter-section > .section-label, .point-of-sale-app > .customer-cart-container.point-of-sale-app > .cart-container.items-selector > .abs-cart-container.filter-section > .cart-label, .point-of-sale-app > .point-of-sale-app.customer-cart-container > .customer-cart-container.customer-section.point-of-sale-app > .cart-container.customer-details.items-selector > .abs-cart-container.header.filter-section > .cart-label, .point-of-sale-app > .customer-cart-container.point-of-sale-app > .customer-section.items-selector > .customer-details.filter-section > .transactions-label, .point-of-sale-app > .point-of-sale-app.customer-cart-container > .customer-cart-container.customer-section.point-of-sale-app > .customer-section.customer-details.items-selector > .customer-details.header.filter-section > .transactions-label, .point-of-sale-app > .customer-cart-container > .customer-section.point-of-sale-app > .customer-details.items-selector > .header.filter-section > .label {
  grid-column: span 4/span 4;
  padding-bottom: var(--padding-xs);
}
.point-of-sale-app > .items-selector > .filter-section > .search-field {
  grid-column: span 5/span 5;
  display: flex;
  align-items: center;
  margin: 0px var(--margin-sm);
}
.point-of-sale-app > .items-selector > .filter-section > .item-group-field {
  grid-column: span 3/span 3;
  display: flex;
  align-items: center;
}
.point-of-sale-app > .items-selector > .items-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: var(--margin-lg);
  padding: var(--padding-lg);
  padding-top: var(--padding-xs);
  overflow-y: scroll;
  overflow-x: hidden;
}
.point-of-sale-app > .items-selector > .items-container:after {
  content: "";
  display: block;
  height: 1px;
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper {
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-base);
  position: relative;
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper:hover {
  transform: scale(1.02, 1.02);
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper .item-qty-pill {
  position: absolute;
  display: flex;
  margin: var(--margin-sm);
  justify-content: flex-end;
  right: 0px;
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper .item-display {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-md);
  margin: var(--margin-sm);
  margin-bottom: 0px;
  min-height: 8rem;
  height: 8rem;
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper .item-img {
  border-radius: 8px 8px 0 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper > .item-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.5rem;
  height: 3.5rem;
  padding-left: var(--padding-sm);
  padding-right: var(--padding-sm);
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper > .item-detail > .item-name {
  display: flex;
  align-items: center;
  font-size: var(--text-md);
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper > .item-detail > .item-rate {
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container {
  grid-column: span 4/span 4;
  display: flex;
  flex-direction: column;
}
.point-of-sale-app > .customer-cart-container > .customer-section {
  display: flex;
  flex-direction: column;
  padding: var(--padding-md) var(--padding-lg);
  overflow: visible;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-field {
  display: flex;
  align-items: center;
  padding-top: var(--padding-xs);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details {
  display: flex;
  flex-direction: column;
  background-color: var(--fg-color);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .header {
  display: flex;
  margin-bottom: var(--margin-md);
  justify-content: space-between;
  padding-top: var(--padding-md);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .header > .close-details-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  color: var(--text-light);
  margin-right: var(--margin-md);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-image > img {
  border-radius: 50%;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-abbr {
  font-size: var(--text-2xl);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-name-desc {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-name-desc > .customer-name {
  font-weight: 700;
  font-size: var(--text-lg);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-name-desc > .customer-desc {
  font-weight: 500;
  font-size: var(--text-sm);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .reset-customer-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-fields-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: var(--margin-md);
  -moz-column-gap: var(--padding-sm);
       column-gap: var(--padding-sm);
  row-gap: var(--padding-xs);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .transactions-label {
  margin-top: var(--margin-md);
  margin-bottom: var(--margin-sm);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-transactions {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-right: -12px;
  padding-right: 12px;
  margin-left: -10px;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-transactions > .no-transactions-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-50);
  border-radius: var(--border-radius-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--margin-md);
  position: relative;
  height: 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: var(--padding-lg);
  width: 100%;
  height: 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-label {
  padding-bottom: var(--padding-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-header {
  display: flex;
  width: 100%;
  font-size: var(--text-md);
  padding-bottom: var(--padding-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-header > .name-header {
  flex: 1 1 0%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-header > .qty-header {
  margin-right: var(--margin-lg);
  text-align: center;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-header > .rate-amount-header {
  text-align: right;
  margin-right: var(--margin-sm);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container .no-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--control-bg);
  border-radius: var(--border-radius-md);
  font-size: var(--text-md);
  font-weight: 500;
  width: 100%;
  height: 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  overflow-y: scroll;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper {
  display: flex;
  align-items: center;
  padding: var(--padding-sm);
  border-radius: var(--border-radius-md);
  margin-right: var(--margin-sm);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper:hover {
  background-color: var(--control-bg);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: var(--border-radius-md);
  color: var(--text-light);
  margin-right: var(--margin-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-abbr {
  font-size: var(--text-lg);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-name-desc {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  flex-shrink: 1;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-name-desc > .item-name {
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-name-desc > .item-desc {
  font-size: var(--text-sm);
  color: var(--gray-600);
  font-weight: 500;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-qty-rate {
  display: flex;
  flex-shrink: 0;
  text-align: right;
  margin-left: var(--margin-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-qty-rate > .item-qty {
  display: flex;
  align-items: center;
  margin-right: var(--margin-lg);
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-qty-rate > .item-rate-amount {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align: right;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-qty-rate > .item-rate-amount > .item-rate {
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-qty-rate > .item-rate-amount > .item-amount {
  font-size: var(--text-md);
  font-weight: 600;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  margin-top: var(--margin-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper {
  display: none;
  align-items: center;
  border-radius: var(--border-radius-md);
  border: 1px dashed var(--gray-500);
  padding: var(--padding-sm) var(--padding-md);
  margin-bottom: var(--margin-sm);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper > .add-discount-field {
  width: 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .discount-icon {
  margin-right: var(--margin-sm);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .edit-discount-btn {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--dark-green-500);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .net-total-container, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .item-qty-total-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-sm) 0px;
  font-weight: 500;
  font-size: var(--text-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .item-qty-total-container {
  padding: 5px 0px 0px 0px;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .taxes-container {
  display: none;
  flex-direction: column;
  font-weight: 500;
  font-size: var(--text-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .taxes-container > .tax-row {
  display: flex;
  justify-content: space-between;
  line-height: var(--text-3xl);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .grand-total-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-sm) 0px;
  font-weight: 700;
  font-size: var(--text-lg);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .checkout-btn {
  background-color: var(--blue-200);
  color: white;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .edit-cart-btn {
  display: none;
  background-color: var(--control-bg);
  font-weight: 500;
  transition: all 0.15s ease-in-out;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .edit-cart-btn:hover {
  background-color: var(--control-bg);
  color: var(--text-light);
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section {
  display: none;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: var(--margin-sm);
  padding: var(--padding-sm);
  padding-bottom: 0px;
  width: 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-totals {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--margin-md);
  font-size: var(--text-md);
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-container {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: var(--margin-md);
  margin-bottom: var(--margin-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-container > .numpad-btn {
  border-radius: var(--border-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-md);
  box-shadow: var(--shadow-sm);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-container > .col-span-2 {
  grid-column: span 2/span 2;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-container > .remove-btn {
  font-weight: 700;
  color: var(--red-500);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .checkout-btn {
  margin: 0px;
  margin-bottom: var(--margin-sm);
  background-color: var(--blue-200);
  color: white;
}
.point-of-sale-app .invoice-wrapper {
  display: flex;
  justify-content: space-between;
  border-radius: var(--border-radius-md);
  padding: var(--padding-sm);
}
.point-of-sale-app .invoice-wrapper:hover {
  background-color: var(--gray-50);
}
.point-of-sale-app .invoice-wrapper > .invoice-name-date {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.point-of-sale-app .invoice-wrapper > .invoice-name-date > .invoice-name {
  font-size: var(--text-md);
  font-weight: 700;
}
.point-of-sale-app .invoice-wrapper > .invoice-name-date > .invoice-date {
  font-size: var(--text-sm);
  display: flex;
  align-items: center;
}
.point-of-sale-app .invoice-wrapper > .invoice-total-status {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: var(--text-sm);
  margin-left: var(--margin-md);
}
.point-of-sale-app .invoice-wrapper > .invoice-total-status > .invoice-total {
  margin-bottom: var(--margin-xs);
  font-size: var(--text-base);
  font-weight: 700;
  text-align: right;
}
.point-of-sale-app .invoice-wrapper > .invoice-total-status > .invoice-status {
  display: flex;
  align-items: center;
  justify-content: right;
}
.point-of-sale-app > .item-details-container {
  grid-column: span 4/span 4;
  display: none;
  flex-direction: column;
  padding: var(--padding-lg);
  padding-top: var(--padding-md);
}
.point-of-sale-app > .item-details-container > .item-details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--margin-md);
}
.point-of-sale-app > .item-details-container > .item-display {
  display: flex;
}
.point-of-sale-app > .item-details-container > .item-display > .item-name-desc-price {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: var(--margin-md);
}
.point-of-sale-app > .item-details-container > .item-display > .item-name-desc-price > .item-name {
  font-size: var(--text-3xl);
  font-weight: 600;
}
.point-of-sale-app > .item-details-container > .item-display > .item-name-desc-price > .item-desc {
  font-size: var(--text-md);
  font-weight: 500;
}
.point-of-sale-app > .item-details-container > .item-display > .item-name-desc-price > .item-price {
  font-size: var(--text-3xl);
  font-weight: 700;
}
.point-of-sale-app > .item-details-container > .item-display > .item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11rem;
  height: 11rem;
  border-radius: var(--border-radius-md);
  margin-left: var(--margin-md);
  color: var(--gray-500);
}
.point-of-sale-app > .item-details-container > .item-display > .item-image > .item-abbr {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-md);
  font-size: var(--text-3xl);
  width: 100%;
  height: 100%;
}
.point-of-sale-app > .item-details-container > .discount-section {
  display: flex;
  align-items: center;
  margin-bottom: var(--margin-sm);
}
.point-of-sale-app > .item-details-container > .discount-section > .item-rate {
  font-weight: 500;
  margin-right: var(--margin-sm);
  text-decoration: line-through;
}
.point-of-sale-app > .item-details-container > .discount-section > .item-discount {
  padding: 3px var(--padding-sm);
  border-radius: var(--border-radius-sm);
  background-color: var(--green-100);
  color: var(--dark-green-500);
  font-size: var(--text-sm);
  font-weight: 700;
}
.point-of-sale-app > .item-details-container > .form-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  -moz-column-gap: var(--padding-md);
       column-gap: var(--padding-md);
}
.point-of-sale-app > .item-details-container > .form-container > .auto-fetch-btn {
  margin: var(--margin-xs);
}
.point-of-sale-app > .payment-container {
  grid-column: span 6/span 6;
  display: none;
  flex-direction: column;
  padding: var(--padding-lg);
}
.point-of-sale-app > .payment-container .border-primary {
  border: 1px solid var(--blue-500);
}
.point-of-sale-app > .payment-container .submit-order-btn {
  background-color: var(--primary-color);
  color: white;
}
.point-of-sale-app > .payment-container .section-label {
  margin-bottom: var(--margin-md);
}
.point-of-sale-app > .payment-container > .payment-modes {
  display: flex;
  padding-bottom: var(--padding-sm);
  margin-bottom: var(--margin-sm);
  overflow-x: scroll;
  overflow-y: hidden;
  flex-shrink: 0;
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper {
  min-width: 40%;
  padding: var(--padding-xs);
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment {
  padding: var(--padding-md) var(--padding-lg);
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .pay-amount {
  display: inline;
  float: right;
  font-weight: 700;
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .mode-of-payment-control {
  display: none;
  align-items: center;
  margin-top: var(--margin-sm);
  margin-bottom: var(--margin-xs);
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .loyalty-amount-name {
  display: none;
  float: right;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .cash-shortcuts {
  display: none;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: var(--margin-sm);
  font-size: var(--text-sm);
  text-align: center;
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .cash-shortcuts > .shortcut {
  border-radius: var(--border-radius-sm);
  background-color: var(--control-bg);
  font-weight: 500;
  padding: var(--padding-xs) var(--padding-sm);
  transition: all 0.15s ease-in-out;
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .cash-shortcuts > .shortcut:hover {
  background-color: var(--control-bg);
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .loyalty-card {
  display: flex;
  flex-direction: column;
}
.point-of-sale-app > .payment-container > .fields-numpad-container {
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
  justify-content: flex-end;
}
.point-of-sale-app > .payment-container > .fields-numpad-container > .fields-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding-bottom: var(--margin-md);
}
.point-of-sale-app > .payment-container > .fields-numpad-container > .fields-section .invoice-fields {
  overflow-y: scroll;
  height: 100%;
  padding-right: var(--padding-sm);
}
.point-of-sale-app > .payment-container > .fields-numpad-container > .number-pad {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: 50%;
}
.point-of-sale-app > .payment-container > .fields-numpad-container > .number-pad .numpad-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: var(--margin-md);
  margin-bottom: var(--margin-md);
}
.point-of-sale-app > .payment-container > .fields-numpad-container > .number-pad .numpad-container > .numpad-btn {
  border-radius: var(--border-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-md);
  box-shadow: var(--shadow-sm);
}
.point-of-sale-app > .payment-container > .totals-section {
  display: flex;
  margin-top: auto;
  margin-bottom: var(--margin-sm);
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
}
.point-of-sale-app > .payment-container > .totals-section > .totals {
  display: flex;
  background-color: var(--control-bg);
  justify-content: center;
  padding: var(--padding-md);
  border-radius: var(--border-radius-md);
}
.point-of-sale-app > .payment-container > .totals-section > .totals > .col {
  flex-grow: 1;
  text-align: center;
}
.point-of-sale-app > .payment-container > .totals-section > .totals > .col > .total-label {
  font-size: var(--text-md);
  font-weight: 500;
}
.point-of-sale-app > .payment-container > .totals-section > .totals > .col > .value {
  font-size: var(--text-2xl);
  font-weight: 700;
}
.point-of-sale-app > .payment-container > .totals-section > .totals > .seperator-y {
  margin-left: var(--margin-sm);
  margin-right: var(--margin-sm);
  border-right: 1px solid var(--gray-300);
}
.point-of-sale-app > .payment-container > .totals-section > .number-pad {
  display: none;
}
.point-of-sale-app > .past-order-list {
  grid-column: span 4/span 4;
  display: none;
  flex-direction: column;
  overflow: hidden;
}
.point-of-sale-app > .past-order-list > .filter-section {
  display: flex;
  flex-direction: column;
  background-color: var(--fg-color);
  padding: var(--padding-lg);
}
.point-of-sale-app > .past-order-list > .filter-section > .search-field {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: var(--margin-md);
  margin-bottom: var(--margin-xs);
}
.point-of-sale-app > .past-order-list > .filter-section > .status-field {
  width: 100%;
  display: flex;
  align-items: center;
}
.point-of-sale-app > .past-order-list > .invoices-container {
  padding: var(--padding-lg);
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.point-of-sale-app > .past-order-summary {
  display: none;
  grid-column: span 6/span 6;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.point-of-sale-app > .past-order-summary > .no-summary-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--gray-50);
  font-weight: 500;
  border-radius: var(--border-radius-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper {
  display: none;
  position: relative;
  width: 31rem;
  height: 100%;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: var(--padding-lg);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: var(--margin-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .left-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: var(--padding-sm);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .left-section > .customer-name {
  font-size: var(--text-2xl);
  font-weight: 700;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .left-section > .customer-email {
  font-size: var(--text-md);
  font-weight: 500;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .left-section > .cashier {
  font-size: var(--text-md);
  font-weight: 500;
  color: var(--gray-600);
  margin-top: auto;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .right-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .right-section > .paid-amount {
  font-size: var(--text-2xl);
  font-weight: 700;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .right-section > .invoice-name {
  font-size: var(--text-md);
  font-weight: 500;
  color: var(--gray-600);
  margin-bottom: var(--margin-sm);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-md);
  background-color: var(--control-bg);
  margin: var(--margin-md) 0px;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .summary-row-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-sm) var(--padding-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .taxes-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0px var(--padding-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .taxes-wrapper > .tax-row {
  display: flex;
  justify-content: space-between;
  font-size: var(--text-md);
  line-height: var(--text-3xl);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper {
  display: flex;
  align-items: center;
  padding: var(--padding-sm) var(--padding-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-name {
  font-weight: 500;
  margin-right: var(--margin-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-qty {
  font-weight: 500;
  margin-left: auto;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-rate-disc {
  display: flex;
  text-align: right;
  margin-left: var(--margin-md);
  justify-content: flex-end;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-rate-disc > .item-disc {
  color: var(--dark-green-500);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-rate-disc > .item-rate {
  font-weight: 500;
  margin-left: var(--margin-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .grand-total {
  font-weight: 700;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .payments {
  font-weight: 700;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-btns {
  display: flex;
  justify-content: space-between;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-btns > .summary-btn {
  flex: 1;
  margin: 0px var(--margin-xs);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-btns > .new-btn {
  background-color: var(--blue-500);
  color: white;
  font-weight: 500;
}

@media screen and (max-width: 620px) {
  .point-of-sale-app {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .point-of-sale-app > .items-selector {
    grid-column: span 6/span 1 !important;
  }
  .point-of-sale-app > .items-selector > .items-container {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .point-of-sale-app > .item-details-container,
  .point-of-sale-app .customer-cart-container {
    grid-column: span 6/span 1;
  }
  .point-of-sale-app > .payment-container {
    overflow: scroll;
  }
  .point-of-sale-app > .payment-container > .fields-numpad-container {
    flex-direction: column-reverse;
  }
  .point-of-sale-app > .payment-container > .fields-numpad-container > .number-pad {
    display: none;
  }
  .point-of-sale-app > .payment-container > .fields-numpad-container > .fields-section {
    width: 100%;
  }
  .point-of-sale-app > .past-order-summary > .invoice-summary-wrapper {
    width: 100%;
  }
  .point-of-sale-app .numpad-totals > span {
    padding: 0 5px;
    font-size: var(--text-sm);
  }
  .point-of-sale-app .col > * {
    font-size: var(--text-sm) !important;
  }
  .point-of-sale-app .control-input-wrapper {
    padding-left: 0.15rem;
  }
  .point-of-sale-app .pay-amount {
    margin-left: 0.2rem;
  }
  .point-of-sale-app .past-order-list {
    grid-column: span 6/span 1;
  }
}